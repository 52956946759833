import { FunctionComponent } from "react";

export type AnnouncementType = {
  className?: string;
};

const Announcement: FunctionComponent<AnnouncementType> = ({
  className = "",
}) => {
  return (
    <footer
      className={`
        self-stretch overflow-hidden
        flex flex-row items-center justify-center
        py-[60px] px-5 lg:px-[100px] xl:px-[200px] 2xl:px-[360px] box-border
        bg-deepskyblue text-white text-left text-3xl font-inter
        ${className}
      `}
    >
      <div
        className={`
          flex-1 flex flex-row items-center justify-start max-w-full
        `}
      >
        <div className="flex-1 overflow-hidden flex flex-col items-start justify-start gap-4 max-w-full">
          {/* Title */}
          <div className="relative font-semibold inline-block max-w-full mq450:text-lg">
            Exciting Update: Beta Testing Available!
          </div>

          {/* Heading */}
          <h1
            className={`
              m-0 self-stretch relative text-41xl font-semibold font-[inherit]
              mq450:text-17xl mq975:text-29xl leading-tight
            `}
          >
            Beta Testing Now Open for iOS!
          </h1>

          {/* Description */}
          <p
            className={`
              self-stretch relative text-xl mq450:text-base
              leading-relaxed
            `}
          >
            Discover the next step in note-taking excellence! Our app is crafted
            to enhance your productivity and streamline your workflow. Be among
            the first to explore its powerful features and help shape its
            development. Beta testing is now open for iOS—
            <a
              href="https://testflight.apple.com/join/3XbcF3Z7"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white font-bold underline hover:opacity-80"
            >
              Join us today!
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Announcement;
