import { FunctionComponent, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/features/auth/authSlice";
import { AppDispatch, RootState } from "../../redux/store"; // Adjust the import path as necessary
import PortalDrawer from "../popup/PortalDrawer";
import MenuBar from "../sidebar/MenuBar";

const TopNavBar: FunctionComponent = () => {
  const [isMenuCustomerBarOpen, setMenuCustomerBarOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const user = useSelector((state: RootState) => state.user.user);

  const openMenuCustomerBar = useCallback(() => {
    if (isLoggedIn) {
      setMenuCustomerBarOpen(true);
    }
  }, [isLoggedIn]);

  const closeMenuCustomerBar = useCallback(() => {
    setMenuCustomerBarOpen(false);
  }, []);

  const handleLogout = async () => {
    await dispatch(logout());
    window.location.reload(); // Manually refresh the page after logout
  };

  const displayName =
    user?.firstName && user?.lastName
      ? `${user.firstName} ${user.lastName}`
      : user?.username || "Guest";

  const displayRole = (role: string) => {
    return role.charAt(0).toUpperCase() + role.slice(1);
  };

  const today = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <>
      <div className="
        w-full
        bg-white
        flex
        items-center
        justify-between
        px-4
        py-3
        border-b
        border-menu-line-grey
        text-strong
        font-inter
      ">
        <div className="flex items-center gap-4">
          {isLoggedIn && (
            <button
              className="w-10 h-10 bg-transparent flex items-center justify-center"
              onClick={openMenuCustomerBar}
            >
              <img alt="Menu" src="/menu/menuBtn.png" className="w-full h-full"/>
            </button>
          )}
          <div className="flex items-center gap-2 pr-4">
              <img
                className="w-[41px] relative h-[41px] object-cover"
                alt="Logo"
                src="/logo/logo.png"
              />
              <div className="text-[26px] font-semi-bold leading-[1.2]">YouWo</div>
          </div>
        </div>
        <div className="flex items-center gap-6">
          {user && (
            <div className="flex flex-col items-end text-sm">
              <span>{displayName}</span>
              <b>{displayRole(user.role)}</b>
            </div>
          )}
          <div className="flex items-center justify-center px-4 py-2 border-[0.9px] border-solid border-menu-line-grey rounded-md">
            <span className="text-black text-sm font-medium">{today}</span>
          </div>
          {isLoggedIn && (
            <button
              className="w-6 h-6 bg-transparent"
              onClick={handleLogout}
            >
              <img alt="Logout" src="/navbar/logoutBtn.svg" />
            </button>
          )}
        </div>
      </div>
      {isMenuCustomerBarOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeMenuCustomerBar}
        >
          <MenuBar onClose={closeMenuCustomerBar} />
        </PortalDrawer>
      )}
    </>
  );
};

export default TopNavBar;
