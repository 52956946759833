import { FunctionComponent } from "react";

export type Section1Type = {
  className?: string;
};

const Section1: FunctionComponent<Section1Type> = ({ className = "" }) => {
  return (
    <section
      className={`
        w-full bg-whitesmoke overflow-hidden
        flex flex-col-reverse lg:flex-row items-center justify-between
        gap-8 px-6 py-12
        ${className}
      `}
    >
      {/* LEFT BLOCK */}
      <div className="flex-1 flex items-center justify-center lg:justify-end">
        <img
          src="/landing/20240809-122439-1@2x.png"
          alt="Visual"
          className="w-full max-w-md lg:max-w-lg h-auto object-cover"
        />
      </div>

      {/* RIGHT BLOCK */}
      <div
        className={`
          flex-1 flex flex-col justify-center
          py-[68.5px] px-[50px] box-border gap-5
          min-w-[416px] max-w-full
          lg:items-start lg:text-left
          items-center text-center
          mq450:py-[29px]
        `}
      >
        {/* Title */}
        <div className="w-full max-w-[567px] text-xl text-cornflowerblue font-medium">
          Audio Booster
        </div>

        {/* Heading */}
        <h1
          className="
            m-0 w-full max-w-[639px] text-41xl font-semibold leading-tight
            lg:text-left text-center
            mq450:text-29xl
          "
        >
          Capture Every Word, Equation, and Insight
        </h1>

        {/* Description */}
        <p
          className="
            w-full max-w-[610px] text-lg
            lg:text-left text-left
            mq450:text-left
          "
        >
          With YouWo‘s audio recorder, you’re equipped with an unparalleled tool
          that ensures not a single detail escapes your notes. Our precise
          speech recognition technology flawlessly captures lectures,
          discussions, and any spoken content, converting them into meticulously
          organized notes. But we don’t stop there—our innovative image-to-text
          and image-to-LaTeX features mean that even complex mathematical
          symbols and equations are effortlessly translated into digital text.
          Say goodbye to the hassle of manually writing down intricate symbols
          or equations. With YouWo, your focus remains on learning, while we
          handle the intricacies of note-taking.
        </p>

        {/* Features */}
        <div
          className={`
            flex flex-col gap-4
            w-full max-w-[500px]
            lg:items-start items-
          `}
        >
          {/* Feature 1 */}
          <div className="flex items-center gap-4">
            <img
              src="/landing/20240809-124049-1@2x.png"
              alt="Feature Icon"
              className="w-7 h-7 object-cover"
            />
            <span className="font-semibold">Speech Recognition</span>
          </div>

          {/* Feature 2 */}
          <div className="flex items-center gap-4">
            <img
              src="/landing/20240809-124049-1@2x.png"
              alt="Feature Icon"
              className="w-7 h-7 object-cover"
            />
            <span className="font-semibold">Image To Text</span>
          </div>

          {/* Feature 3 */}
          <div className="flex items-center gap-4">
            <img
              src="/landing/20240809-124049-1@2x.png"
              alt="Feature Icon"
              className="w-7 h-7 object-cover"
            />
            <span className="font-semibold">Image to Mathematical Expression</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section1;
