import { FunctionComponent } from "react";

export type FeatureType = {
  className?: string;
};

const Feature: FunctionComponent<FeatureType> = ({ className = "" }) => {
  return (
    <section
      className={`
        self-stretch overflow-hidden
        flex flex-col items-center justify-center
        py-[106px] px-5 box-border max-w-full
        text-left text-3xl text-black font-inter
        mq450:pt-[69px] mq450:pb-[69px] mq450:box-border
        ${className}
      `}
    >
      <div
        className={`
          w-full flex flex-wrap items-center justify-center
          gap-10 lg:gap-[10%]
          max-w-[1315px]
        `}
      >
        {/* Feature 1 */}
        <div
          className={`
            flex flex-col items-center lg:items-start
            text-center lg:text-left
            gap-4 max-w-[319px]
          `}
        >
          <div className="rounded-mini bg-cornflowerblue flex items-center justify-center p-2.5">
            <img
              className="h-[30px] w-[30px]"
              loading="lazy"
              alt="Cloud Storage Icon"
              src="/landing/cloud.svg"
            />
          </div>
          <div className="text-xl font-semibold">Cloud Storage</div>
          <p className="text-lg">
            Never lose a note again with our secure, cloud-based storage,
            accessible from anywhere.
          </p>
        </div>

        {/* Feature 2 */}
        <div
          className={`
            flex flex-col items-center lg:items-start
            text-center lg:text-left
            gap-4 max-w-[319px]
          `}
        >
          <div className="rounded-mini bg-cornflowerblue flex items-center justify-center p-2.5">
            <img
              className="h-[30px] w-[30px]"
              loading="lazy"
              alt="Folder Management Icon"
              src="/landing/icbaselinefolder.svg"
            />
          </div>
          <div className="text-xl font-semibold">Folder Management</div>
          <p className="text-lg">
            Keep your notes organized and clutter-free with easy folder
            categorization.
          </p>
        </div>

        {/* Feature 3 */}
        <div
          className={`
            flex flex-col items-center lg:items-start
            text-center lg:text-left
            gap-4 max-w-[319px]
          `}
        >
          <div className="rounded-mini bg-cornflowerblue flex items-center justify-center p-2.5">
            <img
              className="h-[30px] w-[30px]"
              loading="lazy"
              alt="Free Tier Forever Icon"
              src="/landing/fontistodollar.svg"
            />
          </div>
          <div className="text-xl font-semibold">Free Tier Forever!</div>
          <p className="text-lg">
            Enjoy access to essential features for free, forever, supporting
            your educational journey.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Feature;
