import { FunctionComponent } from "react";

export type FooterType = {
  className?: string;
};

const Footer: FunctionComponent<FooterType> = ({ className = "" }) => {
  return (
    <section
      className={`
        self-stretch overflow-hidden flex flex-col lg:flex-row
        items-center lg:items-end justify-center lg:justify-between
        py-7 px-5 lg:px-[100px] xl:px-[200px] 2xl:px-[360px] box-border
        max-w-full gap-5 text-left text-xl text-black font-inter
        mq450:flex-col mq450:justify-start
        ${className}
      `}
    >
      <div
        className={`
          w-full lg:w-auto flex flex-col items-start lg:items-left
          justify-end gap-2.5 lg:gap-4
        `}
      >
        <div className="relative text-base lg:text-xl">
          Copyright © 2024 YouWo.ai
        </div>
        <div className="relative text-base lg:text-xl">
          Powered by YouWo.ai
        </div>
      </div>
    </section>
  );
};

export default Footer;
