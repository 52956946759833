import { FunctionComponent } from "react";

export type IntroType = {
  className?: string;
};

const Intro: FunctionComponent<IntroType> = ({ className = "" }) => {
  return (
    <section
      className={`
        relative
        w-full
        bg-deepskyblue
        overflow-hidden
        ${className}
      `}
    >
      {/* Background image pinned top-right */}
      <img
        src="/landing/rectangle-69@2x.png"
        alt="Background"
        className="
          absolute
          top-0
          left-0
          h-auto
          max-w-none
          object-cover
          /* Adjust width or max-w as desired. E.g.: w-[800px] */
        "
      />

      {/* Main content container (Text left, Image right on larger screens) */}
      <div
        className="
          relative
          z-[1]
          mx-auto
          px-6
          py-12
          flex
          flex-col
          md:flex-row
          items-center
          justify-between
          gap-8
          /* Use container max-w if you like: max-w-7xl or similar */
        "
      >
        {/* Text area */}
        <div className="flex-1 flex flex-col items-start text-white">
          <h1
            className="
              text-41xl
              font-semibold
              leading-tight
              mb-6
              /* If you have custom breakpoints, you can scale text up/down */
            "
          >
            Unlock Your Academic Potential: Experience the Future of Studying
            with AI-Powered Note-Taking
          </h1>
          <p className="text-3xl /* or text-base, text-lg, etc. */">
            Study smarter, not harder, with YouWo: your ultimate study partner.
            Our cutting-edge app transforms your study sessions with superior
            audio recording, image-to-text transcription, and advanced
            AI-driven note summary and analysis. By streamlining the process of
            capturing and reviewing lecture content, YouWo elevates your
            learning efficiency to new heights.
          </p>
        </div>

        {/* Image area (stacks below text on smaller screens) */}
        <div className="flex-1 flex items-center justify-center">
          <img
            src="/landing/screen-1-1@2x.png"
            alt="App Preview"
            className="max-w-full h-auto object-cover"
          />
        </div>
      </div>
    </section>
  );
};

export default Intro;
